<template>
  <div class="ele-body">
    <a-card :bordered="false">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }">
        <a-form-item label="标题:" :labelCol="{ span: 2, offset: 0 }" name="articleName">
          <a-input v-model:value="form.articleTitle" placeholder="请输入标题" allow-clear />
        </a-form-item>
        <a-form-item label="作者" :labelCol="{ span: 2, offset: 0 }" name="author">
          <a-input v-model:value="form.author" placeholder="请输入作者" allow-clear />
        </a-form-item>
        <!-- <a-form-item label="文章封面:" :labelCol="{ span: 2, offset: 0 }" name="sortNumber">
          <a-upload v-model:file-list="fileList" list-type="picture-card" class="avatar-uploader"
            :showUploadList="false" :customRequest="doUpload">
            <img v-if="imageUrl" :src="imageUrl" alt="封面图" height="100" />
            <div v-else>
              <loading-outlined v-if="loading2"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">封面图</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="来源" :labelCol="{ span: 8, offset: 0 }" name="source">
              <a-input v-model:value="form.source" placeholder="请输入来源" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="发布时间" :labelCol="{ span: 8, offset: 0 }" name="createTime">
              <a-date-picker class="ud-fluid" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入发文时间"
                v-model:value="form.createTime" show-time />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="24" :md="24" :sm="24" :xs="24">
            <a-form-item label="文章摘要" :labelCol="{ span: 2, offset: 0 }" name="articleSummary">
              <a-input v-model:value="form.articleSummary" placeholder="请输入文章摘要" allow-clear />
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-form-item label="内容" :labelCol="{ span: 2, offset: 0 }" name="articleContent">
          <tinymce-editor v-model:value="content" :init="{ height: 525 }" />
        </a-form-item>
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="排序:" :labelCol="{ span: 8, offset: 0 }" name="sortNumber">
              <a-input-number v-model:value="form.sortNumber" :min="0" :step="1" />
              <span class="span">数字越小越靠前</span>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="是否公开:" name="isPublic" :labelCol="{ span: 8, offset: 0 }">
              <a-select allow-clear placeholder="状态查询" v-model:value="form.isPublic">
                <a-select-option v-for="item in stateDict" :key="item.dictDataCode" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-form-item
          label="虚拟阅读量:"
          :labelCol="{ span: 2, offset: 0 }"
          name="virtualViews"
        >
          <a-input-number
            v-model:value="form.virtualViews"
            :min="0"
            :step="1"
          />
          <span class="span">显示的阅读量 = 实际阅读量 + 虚拟阅读量 </span>
        </a-form-item> -->
        <a-form-item label=" " :colon="false" :labelCol="{ span: 2, offset: 0 }" name="virtualViews">
          <a-button type="primary" @click="save"> 提交 </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { Modal } from 'ant-design-vue'
import * as articleApi from '@/api/cms/article'
import * as categoryApi from '@/api/cms/category'
import * as dictDataApi from '@/api/sys/dictData'
// import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
// import { reloadPageTab } from '@/utils/page-tab-util'
export default {
  name: 'agreementIndexEdit',
  components: {
    TinymceEditor
    // PlusOutlined,
    // LoadingOutlined
  },
  data() {
    return {
      value: '',
      form: { articleContent: '' },
      rules: [],
      fileList: [],
      loading2: false,
      imageUrl: '',
      categoryList: [],
      // 状态字典项
      stateDict: [],
      isUpdate: false,
      content: '',
      onshow: true
    }
  },
  mounted() {
    this.queryCategoryList()
    this.queryArticle()
    this.queryDataStates()
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to.name === '/frm/cms/agreement/index-edit') {
          this.onshow = true
          this.content = this.form.articleContent
          if (this.$route.query.id) {
            this.queryArticle()
            this.isUpdate = true
          } else {
            this.form = {}
            this.content = ''
            this.isUpdate = false
          }
        } else {
          if (this.onshow) {
            this.onshow = false
            this.form.articleContent = this.content
            this.content = ''
          }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    /* 上传 */
    doUpload({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post('/file/upload', formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.imageUrl = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    save() {
      this.saveProcess()
    },
    saveProcess() {
      const that = this
      const hide = that.$message.loading('提交中..', 0)
      that.$refs.form
        .validate()
        .then(() => {
          that.loading = true
          that.form.imagePath = that.imageUrl
          that.form.categoryId = 8
          that.form.articleContent = that.content
          articleApi
            .save(that.form, that.isUpdate)
            .then((res) => {
              hide()
              if (res.code === 0) {
                Modal.confirm({
                  title: '提示信息',
                  content: '您已修改，是否返回上一页？',
                  okText: '确认',
                  cancelText: '取消',
                  onOk() {
                    that.loading = false

                    that.$message.success(res.msg)
                    that.form.articleContent = res.data
                    that.$router.push({
                      path: './index'
                    })
                  }
                })
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {
          hide()
        })
    },
    /* 获取编辑器纯文本内容 */
    showText() {
      Modal.info({
        maskClosable: true,
        content: this.$util.htmlToText(this.value)
      })
    },
    queryCategoryList() {
      categoryApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.categoryList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryArticle() {
      const query = this.$route.query
      if (query.id > 0) {
        const that = this
        that.isUpdate = true
        const hide = this.$message.loading('请求中..', 0)
        articleApi
          .getById(query.id)
          .then((res) => {
            hide()
            if (res.code === 0) {
              that.form = Object.assign({}, this.data, res.data)
              that.content = that.form.articleContent
              that.imageUrl = res.data.imagePath
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            hide()
            this.$message.error(e.message)
          })
      }
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('isShow')
        .then((res) => {
          if (res.code === 0) {
            this.stateDict = res.data
            this.stateDict.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
.span {
  color: grey;
  padding-left: 10px;
}
</style>
